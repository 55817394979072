
import router from '@/router';

export default {
  data: () => {
    return {
      trackingNumber: '',
    };
  },
  methods: {
    searchTracking: (trackingNumber: number) => {
      router.push(`/${trackingNumber}`);
    },
  },
};
