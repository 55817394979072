
import { LifecycleInfo } from '@/types/types';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  props: {
    lifecycleInfo: {
      type: Object as PropType<LifecycleInfo>,
      required: true,
    },
    step: {
      type: Number,
      required: true,
    },
  },
});
