
import { ContactNumber, LifecycleInfo, TrackingInfoResponse, USPackOrderNumber } from '@/types/types';
import { defineComponent, PropType } from 'vue';
import OrderLifecycle from './OrderLifecycle.vue';

type Data = {
  step: number;
  lifecycleInfo: LifecycleInfo;
  usPackOrderNumber: USPackOrderNumber;
  contactNumber: ContactNumber;
  contactMessage: string;
  deliveryHeading: string;
};

export default defineComponent({
  data(): Data {
    return {
      step: 0,
      lifecycleInfo: {
        receivedEventDateTime: '',
        scheduledEventDateTime: '',
        inTransitEventDateTime: '',
        deliveredEventDateTime: '',
      },
      usPackOrderNumber: 0,
      contactNumber: '',

      contactMessage: '',
      deliveryHeading: '',
    };
  },
  props: {
    order: {
      type: Object as PropType<TrackingInfoResponse>,
      required: true,
    },
  },
  methods: {
    getCurrentStep(): number {
      if (this.lifecycleInfo.deliveredEventDateTime) {
        return 4;
      } else if (this.lifecycleInfo.inTransitEventDateTime) {
        return 3;
      } else if (this.lifecycleInfo.scheduledEventDateTime) {
        return 2;
      } else if (this.lifecycleInfo.receivedEventDateTime) {
        return 1;
      } else {
        return 0;
      }
    },

    setContactMessage(): void {
      switch (this.step) {
        case 4:
          this.contactMessage = `To inquire about your delivery, please call <a href='tel:${this.contactNumber}'>${this.contactNumber}</a>.`;
          break;
        case 3:
        case 2:
        case 1:
          this.contactMessage = `To inquire about the status of or make changes to your delivery, please call <a href='tel:${this.contactNumber}'>${this.contactNumber}</a>.`;
          break;
        case 0:
          this.contactMessage = `This order has not yet been received at our distribution center. Please call <a href='tel:(800)466-3337'>(800) 466-3337</a> to inquire.`;
          break;
      }
    },
    setDeliveryHeading(): void {
      this.deliveryHeading = this.step === 4 ? 'Delivered' : 'Delivery Window';
    },
  },
  components: {
    'order-lifecycle': OrderLifecycle,
  },
  created() {
    const { receivedEventDateTime, scheduledEventDateTime, inTransitEventDateTime, deliveredEventDateTime } =
      this.order;
    Object.assign(this.lifecycleInfo, {
      receivedEventDateTime,
      scheduledEventDateTime,
      inTransitEventDateTime,
      deliveredEventDateTime,
    });
    this.step = this.getCurrentStep();

    this.usPackOrderNumber = this.order.usPackOrderNumber;
    this.contactNumber = this.order.contactNumber;

    this.setDeliveryHeading();
    this.setContactMessage();
  },
});
