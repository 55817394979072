
import { HDOrderNumber, ShipToAddress, TrackingInfoResponse } from '@/types/types';

import { defineComponent } from 'vue';
import OrderCard from '@/components/OrderCard.vue';
import OrderOverviewVue from '@/components/OrderOverview.vue';

type Data = {
  errorMessage: string;
  isLoading: boolean;
  orders: Array<TrackingInfoResponse>;
  reducedOrders: Array<TrackingInfoResponse>;

  shipToAddressInfo: ShipToAddress;
  hdOrderNumber: HDOrderNumber;
};

export default defineComponent({
  name: 'TrackingDetail',
  components: {
    'order-card': OrderCard,
    'order-overview': OrderOverviewVue,
  },
  data: function (): Data {
    return {
      isLoading: false,

      shipToAddressInfo: { address: '', city: '', state: '', zip: '' },
      hdOrderNumber: 0,

      errorMessage: 'Unable to locate this tracking number.',
      orders: new Array<TrackingInfoResponse>(),
      reducedOrders: new Array<TrackingInfoResponse>(),
    };
  },
  methods: {
    getTrackingInfo: function (orderReference: string) {
      this.isLoading = true;
      const uri = `portal/delivery/hd-order?OrderReference=${orderReference}`;
      this.$http.get(uri).then(
        (response) => {
          if (response.data && response.data.length > 0) {
            this.orders = response.data;

            this.reducedOrders = this.removePotentialDuplicates(this.orders);

            // Data to be passed to Order Overview
            this.hdOrderNumber = this.reducedOrders[0].hdOrderNumber;
            const { address, city, state, zip } = this.reducedOrders[0];
            Object.assign(this.shipToAddressInfo, { address, city, state, zip });

            this.isLoading = false;
          } else {
            this.orders = [];
          }
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
    },
    /**
     * Removes duplicate usPackOrderNumber objects, retaining the most recent EventDateTime for each EventType {Received,Scheduled,InTransit,Delivered}
     * @param { Array<TrackingInfoResponse> } orders
     *
     * @returns { Array<TrackingInfoResponse> }
     */
    removePotentialDuplicates(orders: Array<TrackingInfoResponse>): Array<TrackingInfoResponse> {
      return orders.reduce((reducingArray: Array<TrackingInfoResponse>, orderB: TrackingInfoResponse) => {
        const orderIndex = reducingArray.findIndex(
          (order: TrackingInfoResponse) => order.usPackOrderNumber === orderB.usPackOrderNumber
        );

        // If we have an index other than -1, we already have an object with the same order number
        if (orderIndex !== -1) {
          const existingUSPackOrder = reducingArray[orderIndex];

          existingUSPackOrder.receivedEventDateTime = this.getLaterDateTime(
            existingUSPackOrder.receivedEventDateTime,
            orderB.receivedEventDateTime
          );
          existingUSPackOrder.scheduledEventDateTime = this.getLaterDateTime(
            existingUSPackOrder.scheduledEventDateTime,
            orderB.scheduledEventDateTime
          );
          existingUSPackOrder.inTransitEventDateTime = this.getLaterDateTime(
            existingUSPackOrder.inTransitEventDateTime,
            orderB.inTransitEventDateTime
          );
          existingUSPackOrder.deliveredEventDateTime = this.getLaterDateTime(
            existingUSPackOrder.deliveredEventDateTime,
            orderB.deliveredEventDateTime
          );
        } else {
          reducingArray.push(orderB);
        }
        return reducingArray;
      }, new Array<TrackingInfoResponse>());
    },
    getLaterDateTime(dateTimeStringOne: string, dateTimeStringTwo: string) {
      return dateTimeStringOne > dateTimeStringTwo ? dateTimeStringOne : dateTimeStringTwo;
    },
  },
  created: function () {
    const { id } = this.$route.params;

    this.getTrackingInfo(id);
  },
});
