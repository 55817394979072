import Vue from 'vue';
import VueRouter from 'vue-router';
import HomePage from '../views/HomePage.vue';
import TrackingDetail from '../views/TrackingDetail.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage,
  },
  {
    path: '/:id*',
    name: 'TrackingDetail',
    component: TrackingDetail,
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
});

export default router;
