
import { defineComponent, PropType } from 'vue';
import { ShipToAddress } from '@/types/types';
import ShippedToVue from './ShippedTo.vue';

export default defineComponent({
  props: {
    shipToAddressInfo: {
      type: Object as PropType<ShipToAddress>,
      required: true,
    },
    hdOrderNumber: {
      required: true,
    },
  },
  components: {
    'shipped-to': ShippedToVue,
  },
});
