import Vue from 'vue';
import VueResource from 'vue-resource';

Vue.use(VueResource);

let root: string;
switch (process.env.NODE_ENV) {
	case 'production':
		root = 'https://api.gouspack.com';
		break;
	case 'development':
		root = 'http://localhost:20000';
		break;
	default:
		throw new Error(`Unrecognized NODE_ENV value, '${process.env.NODE_ENV}'`);
}

const http: Vue.ComponentOptions<any>['http'] = {
	root
};

const httpOptions: VueResource.Http = (Vue as any)['http'];
httpOptions.options.root = root;

